export default [
    {
        path: '/dashboard/terms/conditions',
        name: 'terms-condition',
        component: () => import('@/views/pages/cloudx/TermsAndConditions'),
    },    {
        path: '/dashboard/privacy/policy',
        name: 'privacy-policy',
        component: () => import('@/views/pages/cloudx/PrivacyPolicy'),
    },
    {
        path: '/dashboard/help/details',
        name: 'help-details',
        component: () => import('@/views/pages/cloudx/Helpline'),
    },

]