export default [
    {
        path: '/admin/settings/youtube-videos',
        name:'videos',
        component:()=> import('@/views/pages/cloudx/settings/Videos.vue')
    },
    {
        path: '/admin/settings/additional-settings',
        name:'additional-settings',
        component:()=> import('@/views/pages/cloudx/settings/AdditionalSettings.vue')
    }
]