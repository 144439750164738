import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    bookingUpdate : 'BOOKING_UPDATE',
    bookingDelete : 'BOOKING_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        booking: [],
        bookingLoading: false,
        bookingError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_BOOKING (state, booking) {
            state.booking = booking;
        },

        BOOKING_LOADING (state, loading) {
            state.bookingLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async toggleStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.bookingUpdate,
                async () => {
                    response= await axios.post(V1API.booking_status+'/'+payload).then(result=>{
                        let data=decode(result.status)

                        return data
                    })

                    dispatch('fetchBooking');

                }
            )
            return response
        },


        async fetchBooking ({ commit },payload) {
            commit('BOOKING_LOADING', true);
            commit('BOOKING_ERROR', false);
            try{

                let result = await axios.get(V1API.booking);
                let response=decode(result.data)

                commit('SET_BOOKING', response);

            }catch(error) {

                commit('BOOKING_ERROR', true);

            }
            commit('BOOKING_LOADING', false);
        },


    },
    getters: {}
}
