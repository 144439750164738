import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    institutionUpdate : 'INSTITUTION_UPDATE',
    institutionDelete : 'INSTITUTION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        institution: [],
        institutionLoading: false,
        institutionError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_INSTITUTION (state, institution) {
            state.institution = institution;
        },

        INSTITUTION_LOADING (state, loading) {
            state.institutionLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        INSTITUTION_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateInstitution({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_institution+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchInstitution');

                }
            )
            return response
        },

        async deleteInstitution({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_institution + payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchInstitution');
                }
            )

            return response
        },

        async fetchInstitution ({ commit },payload) {
            commit('INSTITUTION_LOADING', true);
            commit('INSTITUTION_ERROR', false);
            try{

                let result = await axios.get(V1API.fetch_institution);
                let response=decode(result.data.data)
                commit('SET_INSTITUTION', response); 

            }catch(error) {

                commit('INSTITUTION_ERROR', true);

            }
            commit('INSTITUTION_LOADING', false);
        },

        async createInstitution({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_institution,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchInstitution')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}