import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule';
import app from './app';
import appConfig from './cloudX/portfolio/app-config';
import verticalMenu from './vertical-menu';
import moduleAuth from "@/store/auth/moduleAuth";
import moduleUserManagement from "@/store/cloudX/userManagement/moduleUserManagement";
import moduleForgotPassword from "@/store/cloudX/forgotPassword/moduleForgotPassword";
import moduleDash from "@/store/cloudX/dashboard/moduleDash";

import moduleConcern from "@/store/cloudX/concern/moduleConcern";
import moduleCategories from "@/store/cloudX/categories/moduleCategories";
import moduleProject  from"@/store/cloudX/portfolio/moduleProject"
import moduleMedia from "@/store/cloudX/media/moduleMedia";
import moduleCampaign from "@/store/cloudX/campaign/moduleCampaign";
import moduleContact from "@/store/cloudX/contacts/moduleContact";
import moduleJobPost from "@/store/cloudX/jobPost/moduleJobPost";
import moduleJobApplication from "@/store/cloudX/jobApplications/moduleJobApplication";
import moduleGallery from "@/store/cloudX/gallery/moduleGallery";
import moduleBookings from "@/store/cloudX/studio/moduleBookings";

import moduleDashboard from '@/store/biyerJuti/dashboard/moduleDashboard';
import moduleCountry from "@/store/biyerJuti/country/moduleCountry";
import moduleDistrict from "@/store/biyerJuti/district/moduleDistrict";
import moduleArea from "@/store/biyerJuti/area/moduleArea";
import moduleProfession from "@/store/biyerJuti/profession/moduleProfession";
import modulePosition from "@/store/biyerJuti/position/modulePosition";
import moduleOrganization from "@/store/biyerJuti/organization/moduleOrganization";
import moduleDegree from "@/store/biyerJuti/degree/moduleDegree";
import moduleSubject from "@/store/biyerJuti/subject/moduleSubject";
import moduleInstitution from "@/store/biyerJuti/institution/moduleInstitution";
import moduleUser from "@/store/biyerJuti/user/user";
import moduleHearts from '@/store/biyerJuti/hearts/moduleHearts';
import moduleContactMessage from '@/store/biyerJuti/contact/moduleContact';
import moduleAdmin from '@/store/biyerJuti/admin/moduleAdmin';
import moduleFaq from '@/store/biyerJuti/faq/moduleFaq';
import moduleAd from '@/store/biyerJuti/ad/ad';
import moduleSettings from '@/store/biyerJuti/settings/settings';
import moduleHobby from './biyerJuti/hobby/moduleHobby';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        'app-ecommerce': ecommerceStoreModule,
        'auth': moduleAuth,
        'user': moduleUserManagement,
        'forgotPassword':moduleForgotPassword,
        'dashboard':moduleDash,
        'concern':moduleConcern,
        'category':moduleCategories,
        'project':moduleProject,
        'campaign':moduleCampaign,
        'media':moduleMedia,
        'contact':moduleContact,
        'job':moduleJobPost,
        'application':moduleJobApplication,
        'gallery':moduleGallery,
        'booking':moduleBookings,

        'summary':moduleDashboard,
        'country':moduleCountry,
        'district':moduleDistrict,
        'area':moduleArea,
        'profession':moduleProfession,
        'position':modulePosition,
        'organization':moduleOrganization,
        'degree':moduleDegree,
        'subject':moduleSubject,
        'institution':moduleInstitution,
        'users':moduleUser,
        'hearts':moduleHearts,
        'message':moduleContactMessage,
        'admin':moduleAdmin,
        'faq':moduleFaq,
        'ad':moduleAd,
        'settings':moduleSettings,
        'hobby':moduleHobby
    },
    strict: process.env.DEV,
})
