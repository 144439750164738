import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    summaryUpdate : 'SUMMARY_UPDATE',
    summaryDelete : 'SUMMARY_DELETE'
};

const mutations = {}; 
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        summary: {},
        summaryLoading: false,
        submitError: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_SUMMARY (state, summary) {
            state.summary = summary;
        },

        SUMMARY_LOADING (state, loading) {
            state.summaryLoading = loading;
        },

        SUMMARY_ERROR(state, error) {
            state.submitError = error;
        }
    },

    actions: {
        async fetchSummary ({ commit },payload) {
            commit('SUMMARY_LOADING', true);
            commit('SUMMARY_ERROR', false);
            try{

                let result = await axios.get(V1API.get_summary);
                let response=decode(result.data)
                commit('SET_SUMMARY', response); 

            }catch(error) {

                commit('SUMMARY_ERROR', true);

            }
            commit('SUMMARY_LOADING', false);
        }
    },
    
    getters: {}
}