export default [
    {
        path: '/admin/admins',
        name:'admins',
        component:()=> import('@/views/pages/cloudx/administration/Admins.vue')
    },
    {
        path: '/admin/contact-requests',
        name:'contact-request',
        component:()=> import('@/views/pages/cloudx/administration/Contact-request.vue')
    },
    {
        path: '/admin/user-reports',
        name:'activity-log',
        component:()=> import('@/views/pages/cloudx/administration/Activity.vue')
    },
    {
        path: '/admin/choose-viewers',
        name:'choose-viewers',
        component:()=> import('@/views/pages/cloudx/administration/ChooseViewers.vue')
    },
    {
        path: '/admin/choose-viwer/:id',
        component:()=> import('@/views/pages/cloudx/administration/ViewerDetail.vue')
    },
    {
        path: '/admin/report/activity-log/:id',
        component:()=> import('@/views/pages/cloudx/administration/Log.vue')
    },
    {
        path: '/admin/report/summary/:id',
        component:()=> import('@/views/pages/cloudx/administration/Summary.vue')
    },
    {
        path: '/admin/faqs',
        name:'faqs',
        component:()=> import('@/views/pages/cloudx/administration/Faqs.vue')
    },
    {
        path: '/admin/edit-by-admin',
        name:'edit-by-admin',
        component:()=> import('@/views/pages/cloudx/administration/EditByAdmin.vue')
    },
    {
        path: '/admin/edit-by-admin/activity-log/:id',
        component:()=> import('@/views/pages/cloudx/administration/AdminLog.vue')
    }
]