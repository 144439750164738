import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {decode, encode} from "@/store/dataMagic";

const keyList = {
    forgotPasswordUpdate: 'FORGOT_PASSWORD_UPDATE',
    forgotPasswordDelete: 'FORGOT_PASSWORD_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        forgotPasswordLoading: false,
        forgotPasswordError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },
    mutations: {
        ...mutations,
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {


        async RequestPasswordReset({commit}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.forgotPasswordUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.password_reset_request, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                }
            )
            return response
        },
        async validateResetToken({commit}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.forgotPasswordUpdate,
                async () => {
                    let magic = encode(payload)
                    console.log('here')
                    response = await axios.post(V1API.validate_reset_token, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                }
            )
            return response
        },

        async updatePassword({commit}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.forgotPasswordUpdate,
                async () => {
                    let magic = encode(payload)
                    response = await axios.post(V1API.reset_password, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)

                        return data
                    })

                }
            )
            return response
        },


    },
    getters: {}
}
