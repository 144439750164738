export default [
    {
      path: '/admin/list-by-user-behaviour/android',
      name:'android-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Android-Users.vue')
    },
    {
      path: '/admin/list-by-user-behaviour/ios',
      name:'ios-users',
      component:()=> import('@/views/pages/cloudx/subscribers/Ios-Users.vue')
    },
    {
      path: '/admin/list-by-user-behaviour/online',
      name:'online',
      component:()=> import('@/views/pages/cloudx/subscribers/Online-user.vue')
    },
    {
      path: '/admin/list-by-user-behaviour/high-activity',
      name:'high-activity',
      component:()=> import('@/views/pages/cloudx/subscribers/High-activity.vue')
    },
    {
      path: '/admin/list-by-user-behaviour/low-activity',
      name:'low-activity',
      component:()=> import('@/views/pages/cloudx/subscribers/Low-activity.vue')
    },
    {
      path: '/admin/list-by-user-behaviour/no-activity',
      name:'no-activity',
      component:()=> import('@/views/pages/cloudx/subscribers/No-activity.vue')
    }
]