export default [
    {
        path: '/admin/database/country',
        name: 'country',
        component: () => import('@/views/pages/cloudx/databaseManagement/Country.vue'),
    },    {
        path: '/admin/database/district',
        name: 'district',
        component: () => import('@/views/pages/cloudx/databaseManagement/District.vue'),
    },
    {
        path: '/admin/database/area',
        name: 'area',
        component: () => import('@/views/pages/cloudx/databaseManagement/Area.vue'),
    },
    {
        path: '/admin/database/industry-category',
        name: 'profession',
        component: () => import('@/views/pages/cloudx/databaseManagement/Profession.vue'),
    },
    {
        path: '/admin/database/designation',
        name: 'position',
        component: () => import('@/views/pages/cloudx/databaseManagement/Position.vue'),
    },
    {
        path: '/admin/database/organization',
        name: 'organization',
        component: () => import('@/views/pages/cloudx/databaseManagement/Organization.vue'),
    },
    {
        path: '/admin/database/degree',
        name: 'degree',
        component: () => import('@/views/pages/cloudx/databaseManagement/Degree.vue'),
    },
    {
        path: '/admin/database/subject',
        name: 'subject',
        component: () => import('@/views/pages/cloudx/databaseManagement/Subject.vue'),
    },
    {
        path: '/admin/database/institution',
        name: 'institution',
        component: () => import('@/views/pages/cloudx/databaseManagement/Institution.vue'),
    },
    {
        path: '/admin/database/hobby',
        name: 'hobby',
        component: () => import('@/views/pages/cloudx/databaseManagement/Hobbies.vue'),
    }
]