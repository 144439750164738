import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    countryUpdate : 'COUNTRY_UPDATE',
    countryDelete : 'COUNTRY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        country: [],
        countryLoading: false,
        countryError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_COUNTRY (state, country) {
            state.country = country;
        },

        COUNTRY_LOADING (state, loading) {
            state.countryLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },
        COUNTRY_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateCountry({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_country+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchCountry');

                }
            )
            return response
        },



        async deleteCountry({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_country+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchCountry');
                }
            )

            return response
        },

        async fetchCountry ({ commit }) {
            commit('COUNTRY_LOADING', true);
            commit('COUNTRY_ERROR', false);
            try{

                let result = await axios.get(V1API.fetch_country);
                let response=decode(result.data.data)
                commit('SET_COUNTRY', response); 

            }catch(error) {

                commit('COUNTRY_ERROR', true);

            }
            commit('COUNTRY_LOADING', false);
        },
        
        async addCountry({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_country,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCountry')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}