import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    jobUpdate : 'JOB_UPDATE',
    jobDelete : 'JOB_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        job: [],
        jobLoading: false,
        jobError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_JOB (state, job) {
            state.job = job;
        },

        JOB_LOADING (state, loading) {
            state.jobLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateJob({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.jobUpdate,
                async () => {

                    let magic=encode(payload.data)
                    response = await axios.patch(V1API.job_edit+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return result.status
                    })

                    // dispatch('fetchJob');

                }
            )
            return response
        },


        async toggleActiveStatus({commit, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.jobUpdate,
                async () => {
                    response= await axios.get(V1API.job_status+'/'+payload).then(result=>{
                        let data=decode(result.data)
                        return data.status
                    })

                    dispatch('fetchJob');

                }
            )
            return response
        },
        async deleteJob({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.jobDelete,
                async () => {

                    response = await axios.delete(V1API.job_delete+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchJob');
                }
            )

            return response
        },

        async fetchJob ({ commit },payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            try{

                let result = await axios.get(V1API.job_get);
                let response=decode(result.data.data)
                console.log(response)
                commit('SET_JOB', response);

            }catch(error) {

                commit('JOB_ERROR', true);

            }
            commit('JOB_LOADING', false);
        },

        async fetchJobByID ({ commit },payload) {
            commit('JOB_LOADING', true);
            commit('JOB_ERROR', false);
            try{

                let result = await axios.get(V1API.job_get+'/'+payload);
                let response=decode(result.data.data)
                console.log(response)
                commit('SET_JOB', response);

            }catch(error) {

                commit('JOB_ERROR', true);

            }
            commit('JOB_LOADING', false);
        },
        async addJob({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.job_add,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return result.status
                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchJob')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
