import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    userUpdate : 'USER_UPDATE',
    userDelete : 'USER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        users: [],
        details: {},
        userLoading: false,
        userError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_USERS (state, users) {
            state.users = users;
        },

        USER_LOADING (state, loading) {
            state.userLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        USER_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async fetchHeartExchanges ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_heart_exchanges); 
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchMatches ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_matches);
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByPremiumStatus ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_premium_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByGender ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_gender + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async updateAccountStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let response = await axios.get(V1API.update_account_status + payload.id + '/' + payload.status).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch('fetchUsers')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }

    },

    getters: {}
}