import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    galleryUpdate : 'GALLERY_UPDATE',
    galleryDelete : 'GALLERY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        gallery: [],
        galleryLoading: false,
        galleryError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_GALLERY (state, gallery) {
            state.gallery = gallery;
        },

        GALLERY_LOADING (state, loading) {
            state.galleryLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async updateGallery({commit, state, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let data = new FormData();

                let payloadData=buildFormData(data,payload.data);
                // console.log(...payloadData)
                let headerConfig=  {
                    "Content-Type": "multipart/form-data"
                }

                let response = await axios.patch(V1API.gallery_update+payload.slug,payloadData,headerConfig).then(result=>{
                    let data=decode(result)
                    console.log(result.status)
                    return data.status

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchGalleryByID',payload.slug)
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },



        async deleteGallery({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.galleryDelete,
                async () => {

                    response = await axios.delete(V1API.gallery_get+'/'+payload).then(result=>{
                        let data=decode(result.data)
                        return data.message

                    });
                    dispatch('fetchGallery');
                }
            )

            return response
        },

        async fetchGallery ({ commit },payload) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);
            try{

                let result = await axios.get(V1API.gallery_get);
                let response=decode(result.data)

                commit('SET_GALLERY', response);

            }catch(error) {

                commit('GALLERY_ERROR', true);

            }
            commit('GALLERY_LOADING', false);
        },
        async fetchGalleryByID ({ commit },payload) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);
            try{

                let result = await axios.get(V1API.gallery_by_id+payload);
                let response=decode(result.data)

                commit('SET_GALLERY', response);

            }catch(error) {

                commit('GALLERY_ERROR', true);

            }
            commit('GALLERY_LOADING', false);
        },
        async fetchGalleryByConcern ({ commit },payload) {
            commit('GALLERY_LOADING', true);
            commit('GALLERY_ERROR', false);
            try{

                let result = await axios.get(V1API.gallery_get_by_concern+'/'+payload.id);
                let response=decode(result.data)

                commit('SET_GALLERY', response);

            }catch(error) {

                commit('GALLERY_ERROR', true);

            }
            commit('GALLERY_LOADING', false);
        },
        async addGallery({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let data = new FormData();

                let payloadData=buildFormData(data,payload);
                let headerConfig=  {
                    "Content-Type": "multipart/form-data"
                }
                let response = await axios.post(V1API.gallery_add,payloadData,headerConfig).then(result=>{
                    let data=decode(result)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch ('fetchGallery')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
