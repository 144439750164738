import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    namespaced: true,
    state : {
        statistics: {},

        totalLoading: false,
        totalError: false,
        reportLoading: false,
        reportError: false,
        submitLoading: false,
        submitError: false,
    },
    mutations : {
        SET_TOTAL_STATISTICS(state, value) {
            state.statistics = value;
        },

        TOTAL_LOADING (state, loading) {
            state.totalLoading = loading;
        },

        TOTAL_ERROR (state, hasError) {
            state.totalError = hasError;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        }
    },
    actions: {
        async fetchStatistics({ commit },payload) {
            commit('TOTAL_LOADING', true);
            commit('TOTAL_ERROR', false);

            try{
                let response = await axios.get(V1API.dash_statistics);
                console.log(response.data.data)
                commit('SET_TOTAL_STATISTICS', response.data.data);
            } catch(error) {
                commit('TOTAL_ERROR', true);
            }
            commit('TOTAL_LOADING', false);
        },


    },
    getters: {}
}
