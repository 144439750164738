import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    contactUpdate : 'CONTACT_UPDATE',
    contactDelete : 'CONTACT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        contact: [],
        contactLoading: false,
        contactError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_CONTACT (state, contact) {
            state.contact = contact;
        },

        CONTACT_LOADING (state, loading) {
            state.contactLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async deleteContact({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.contactDelete,
                async () => {

                    response = await axios.delete(V1API.contact_delete+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchContact');
                }
            )

            return response
        },

        async fetchContact ({ commit },payload) {
            commit('CONTACT_LOADING', true);
            commit('CONTACT_ERROR', false);
            try{

                let result = await axios.get(V1API.contact_get);
                let response=decode(result.data)
                console.log(response.data)
                commit('SET_CONTACT', response.data);

            }catch(error) {

                commit('CONTACT_ERROR', true);

            }
            commit('CONTACT_LOADING', false);
        },
        async fetchContactByID ({ commit },payload) {
            commit('CONTACT_LOADING', true);
            commit('CONTACT_ERROR', false);
            try{

                let result = await axios.get(V1API.contact_get+'/'+payload);
                let response=decode(result.data)
                console.log(response)
                commit('SET_CONTACT', response);

            }catch(error) {

                commit('CONTACT_ERROR', true);

            }
            commit('CONTACT_LOADING', false);
        },



    },
    getters: {}
}
