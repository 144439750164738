import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    categoryUpdate : 'CATEGORY_UPDATE',
    categoryDelete : 'CATEGORY_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        category: [],
        categoryLoading: false,
        categoryError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_CATEGORY (state, category) {
            state.category = category;
        },

        CATEGORY_LOADING (state, loading) {
            state.categoryLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateCategory({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.categoryUpdate,
                async () => {
                    let payloadData={
                        title: payload.title
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.category+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchCategory');

                }
            )
            return response
        },
        async toggleGlance({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {

                    response = await axios.get(V1API.category_by_concern+ payload+'/toggle-glance').then(result => {
                        let data = decode(result)

                        return data.status

                    });
                    dispatch('fetchCategory');
                }
            )

            return response
        },


        async deleteCategory({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.categoryDelete,
                async () => {

                    response = await axios.delete(V1API.category+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchCategory');
                }
            )

            return response
        },

        async fetchCategory ({ commit },payload) {
            commit('CATEGORY_LOADING', true);
            commit('CATEGORY_ERROR', false);
            try{

                let result = await axios.get(V1API.category);
                let response=decode(result.data)

                commit('SET_CATEGORY', response);

            }catch(error) {

                commit('CATEGORY_ERROR', true);

            }
            commit('CATEGORY_LOADING', false);
        },
        async fetchCategoryByConcern ({ commit },payload) {
            commit('CATEGORY_LOADING', true);
            commit('CATEGORY_ERROR', false);
            try{

                let result = await axios.get(V1API.category_by_concern+payload.id);
                let response=decode(result.data)

                commit('SET_CATEGORY', response);

            }catch(error) {

                commit('CATEGORY_ERROR', true);

            }
            commit('CATEGORY_LOADING', false);
        },
        async addCategory({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.category,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)
                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchCategory')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
