import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    userUpdate : 'USER_UPDATE',
    userDelete : 'USER_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        users: [],
        further_reviews: [],
        logs: [],
        admin_feedbacks: {},
        summary: {},
        details: {},
        parameters: {},
        userLoading: false,
        userError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_USERS (state, users) {
            state.users = users;
        },

        SET_FURTHER_REVIEW (state, users) {
            state.further_reviews = users;
        },

        SET_USER_DETAILS (state, user) {
            state.details = user;
        },

        USER_LOADING (state, loading) {
            state.userLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        USER_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        SET_LOGS (state, logs) {
            state.logs = logs;
        },

        SET_SUMMARY (state, summary) {
            state.summary = summary;
        },

        SET_PARAMETERS (state, items) {
            state.parameters = items;
        },

        SET_ADMIN_FEEDBACKS (state, admin_feedbacks) {
            state.admin_feedbacks = admin_feedbacks;
        }
    },

    actions: {
        async updateUser({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.update_user+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data) 
                        return data.message
                    })

                    dispatch('fetchArea');

                }
            )
            return response
        },

        async deleteUser({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_area+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchArea');
                }
            )

            return response
        },

        async fetchUsers ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users);
                let response=decode(result.data.data)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUserDetails ({ commit }, payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_user_details + payload);
                let response=decode(result.data.data)
                commit('SET_USER_DETAILS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByStatus ({ commit },payload) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },
        
        async fetchFurtherReviewList ({ commit }) {
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.get_further_review_list);
                let response=decode(result.data.user_info)
                commit('SET_FURTHER_REVIEW', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByPremiumStatus ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_premium_status + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByStatusByPremiumStatus ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_status_premium_status + payload.status + '?premium_status=' + payload.prem_status);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByStatusByGender ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_status_premium_status + payload.status + '?gender=' + payload.gender);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByGender ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_gender + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchUsersByDevice ({ commit },payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_users_by_device + payload);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchHighActivityUser ({ commit }) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.high_activity_user);
                let response=decode(result.data.high_activity_users)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchLowActivityUser ({ commit }) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.low_activity_user);
                let response=decode(result.data.low_activity_users)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchNoActivityUser ({ commit }) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.no_activity_user);
                let response=decode(result.data.no_activity_users)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchOnlineUser ({ commit }) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.online_user);
                let response=decode(result.data.user_info)
                commit('SET_USERS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async updateAccountStatus({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let response = await axios.post(V1API.update_account_status + payload.pid + '/' + payload.status, {
                    page: payload.page

                }).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch('fetchUsers')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePremiumStatus({commit}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.update_premium_status + payload.pid + '/' + payload.status).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async activatedAllCheckedProfiles({commit}) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.activate_all_checked_user).then(result=>{
                    let data=result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async activateSelectedProfiles({commit}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);

            try{ 
                let response = await axios.post(V1API.activate_selected_user, {

                    user_ids: payload

                }).then(result=>{

                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchActivityLogByUser ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_activity_log_by_user + payload);
                let response=decode(result.data.activity_log)
                commit('SET_LOGS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchSummaryByUser ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_summary_by_user + payload);
                let response=decode(result.data)
                commit('SET_SUMMARY', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async fetchSavedParameters ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);
            try{
                let result = await axios.get(V1API.fetch_saved_parameters + payload);
                let response = decode(result.data.data)
                commit('SET_PARAMETERS', response); 

            }catch(error) {
                commit('USER_ERROR', true);
            }
            commit('USER_LOADING', false);
        },

        async updatePersonalInformations ({ commit, dispatch }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.patch(V1API.update_personal_informations + payload.pid, payload.user).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                dispatch('fetchUserDetails', payload.pid)
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateEducationalInfo ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.patch(V1API.update_educational_informations + payload.education.id + '/' + payload.pid, 
                    {
                        subject:payload.education.subject,
                        passing_year:payload.education.passing_year,
                        institution_name:payload.education.institution_name,
                        country:payload.education.country

                    }).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateProfessionalInfo ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.patch(V1API.update_professional_informations + payload.prof.id + '/' + payload.pid, 
                    {
                        organization_name:payload.prof.organization_name,

                    }).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updateFamilyInfo ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.post(V1API.update_family_informations + payload.pid, 
                    {
                        my_bio: payload.family.my_bio,
                        family_details: payload.family.family_details

                    }).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePresentAddress ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.post(V1API.update_present_address + payload.pid, payload.address).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async updatePermanentAddress ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.post(V1API.update_permanent_address + payload.pid, payload.address).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async deleteProfilePictures ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.delete(V1API.delete_profile_picture + payload.pid + '/' + payload.img + '/').then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async addAdminFeedback ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.post(V1API.add_feedback + payload.pid, {

                    feedback: payload.feedback

                }).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async submitFeedbacks ({ commit }, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.post(V1API.add_all_feedbacks + payload.pid, {

                    feedbacks: payload.feedbacks

                }).then(result=>{
                    let data=result.data
                    return data
                });

                commit('SUBMIT_SUCCESS', true);
                return response

            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

        async fetchAdminFeedbacks ({ commit }, payload) { 
            commit('USER_LOADING', true);
            commit('USER_ERROR', false);

            try{
                let result = await axios.get(V1API.get_all_feedbacks + payload);
                let response = result.data.feedbacks
                commit('SET_ADMIN_FEEDBACKS', response); 

            }catch(error) {

                commit('USER_ERROR', true);

            }
            commit('USER_LOADING', false);
        },

        async toggleFeedbackResolved({commit}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{ 
                let response = await axios.get(V1API.toggle_feedback_resolved + payload.pid + '/' + payload.section).then(result=>{
                    let data = result.data

                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                return response
                
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        }
    },

    getters: {}
}