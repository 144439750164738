import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    projectUpdate: 'MEDIA_UPDATE',
    projectDelete: 'MEDIA_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        media: [],
        mediaLoading: false,
        mediaError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_MEDIA(state, media) {
            state.media = media;
        },

        MEDIA_LOADING(state, loading) {
            state.mediaLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async updateMedia({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.mediaUpdate,
                async () => {
                    let payloadData = {
                        title: payload.title
                    }
                    let magic = encode(payloadData)
                    response = await axios.patch(V1API.media + "/" + payload.id, magic.encodedPayload, magic.config).then(result => {
                        let data = decode(result.data)
                        return data.message
                    })

                    dispatch('fetchMedia');

                }
            )
            return response
        },


        async deleteMedia({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.mediaDelete,
                async () => {
                    response = await axios.delete(V1API.media_add + '/' + payload.id).then(result => {
                        let data = decode(result.data)
                        return data.message

                    });
                    dispatch('fetchMediaByProject', {id: payload.project_id}
                    );
                }
            )

            return response
        },

        async fetchMedia({commit}, payload) {
            commit('MEDIA_LOADING', true);
            commit('MEDIA_ERROR', false);
            try {
                let result = await axios.get(V1API.media);
                let response = decode(result.data)

                commit('SET_MEDIA', response);
            } catch (error) {
                commit('MEDIA_ERROR', true);
            }
            commit('MEDIA_LOADING', false);
        },
        async fetchMediaByProject({commit}, payload) {
            commit('MEDIA_LOADING', true);
            commit('MEDIA_ERROR', false);
            try {

                let result = await axios.get(V1API.media_by_id + payload.id)
                let response = decode(result.data)

                commit('SET_MEDIA', response);


            } catch (error) {
                console.log(error)
                commit('MEDIA_ERROR', true);

            }
            commit('MEDIA_LOADING', false);
        },
        async addMedia({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let data = new FormData();

                let payloadData = buildFormData(data, payload);
                // console.log(...payloadData)
                let headerConfig = {
                    "Content-Type": "multipart/form-data"
                }

                let response = await axios.post(V1API.media_add, payloadData, headerConfig).then(result => {
                    let data = decode(result.status)
                    return data

                });
                dispatch('fetchMediaByProject', {id: payload.project_id})
                commit('SUBMIT_SUCCESS', true);

                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
