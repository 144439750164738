import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    projectUpdate: 'PROJECT_UPDATE',
    projectDelete: 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state: {
        project: [],
        projectLoading: false,
        projectError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations: {
        ...mutations,
        SET_PROJECT(state, project) {
            state.project = project;
        },

        PROJECT_LOADING(state, loading) {
            state.projectLoading = loading;
        },
        SUBMIT_LOADING(state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR(state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS(state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {

        async updateProject({commit, state, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let data = new FormData();

                let payloadData = buildFormData(data, payload.data);
                // console.log(...payloadData)
                let headerConfig = {
                    "Content-Type": "multipart/form-data"
                }
                let response = await axios.patch(V1API.project_update + payload.slug, payloadData, headerConfig).then(result => {
                    let data = decode(result)
                    return data.status

                });

                commit('SUBMIT_SUCCESS', true);
                // dispatch('fetchProjectByID', payload.slug)
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },


        async deleteProject({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.projectDelete,
                async () => {

                    response = await axios.delete(V1API.project_get + '/' + payload).then(result => {
                        let data = decode(result.data)
                        return data.message

                    });
                    dispatch('fetchProject');
                }
            )

            return response
        },
        async toggleGlance({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {

                    response = await axios.get(V1API.project_glance_toggle + payload.id).then(result => {
                        let data = decode(result)
                        console.log(data)
                        return data.status

                    });

                    console.log(payload.concern_id)
                    dispatch('fetchProjectByConcern',{id: payload.concern_id});
                }
            )

            return response
        },
        async toggleAtAGlance({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.projectUpdate,
                async () => {

                    response = await axios.get(V1API.project_at_a_glance_toggle + payload.id).then(result => {
                        let data = decode(result)
                        console.log(data)
                        return data.status

                    });
                    console.log(payload.concern_id)
                    dispatch('fetchProjectByConcern', {id: payload.concern_id});
                }
            )

            return response
        },
        async updateProjectWeight({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.projectDelete,
                async () => {

                    response = await axios.post(V1API.weight_updater+ payload.weight_slug, payload.body).then(result => {
                        let data = decode(result.data)
                        return data.message

                    });
                    dispatch('fetchProject');
                }
            )

            return response
        },

        async fetchProject({commit}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            try {

                let result = await axios.get(V1API.project_get);
                let response = decode(result.data)

                commit('SET_PROJECT', response);

            } catch (error) {

                commit('PROJECT_ERROR', true);

            }
            commit('PROJECT_LOADING', false);
        },
        async fetchProjectByID({commit}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            try {

                let result = await axios.get(V1API.project_by_id + payload);
                let response = decode(result.data)

                commit('SET_PROJECT', response);

            } catch (error) {

                commit('PROJECT_ERROR', true);

            }
            commit('PROJECT_LOADING', false);
        },
        async fetchProjectByConcern({commit}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);
            try {

                let result = await axios.get(V1API.project_get_by_concern + '/' + payload.id);
                let response = decode(result.data)

                commit('SET_PROJECT', response);

            } catch (error) {

                commit('PROJECT_ERROR', true);

            }
            commit('PROJECT_LOADING', false);
        },
        async addProject({commit, dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try {
                let data = new FormData();

                let payloadData = buildFormData(data, payload);
                let headerConfig = {
                    "Content-Type": "multipart/form-data"
                }
                let response = await axios.post(V1API.project_add, payloadData, headerConfig).then(result => {
                    let data = decode(result)
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                //dispatch('fetchProject')
                return response
            } catch (e) {
                commit('SUBMIT_ERROR', true);
            } finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
