import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    professionUpdate : 'PROFESSION_UPDATE',
    professionDelete : 'PROFESSION_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,

    state : {
        profession: [],
        professionLoading: false,
        professionError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,

        ...store
    },

    mutations : {
        ...mutations,

        SET_PROFESSION (state, profession) {
            state.profession = profession;
        },

        PROFESSION_LOADING (state, loading) {
            state.professionLoading = loading;
        },

        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        PROFESSION_ERROR(state, error) {
            state.submitError = error;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },

    actions: {
        async updateProfession({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.countryUpdate,
                async () => {
                    let payloadData={ 
                        name: payload.name
                    }
                    let magic=encode(payloadData)
                    response = await axios.post(V1API.update_profession+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchProfession');

                }
            )
            return response
        },

        async deleteProfession({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.countryDelete,
                async () => {

                    response = await axios.delete(V1API.delete_profession + payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchProfession');
                }
            )

            return response
        },

        async fetchProfession ({ commit },payload) {
            commit('PROFESSION_LOADING', true);
            commit('PROFESSION_ERROR', false);
            try{

                let result = await axios.get(V1API.fetch_profession);
                let response=decode(result.data.data)
                commit('SET_PROFESSION', response); 

            }catch(error) {

                commit('PROFESSION_ERROR', true);

            }
            commit('PROFESSION_LOADING', false);
        },

        async createProfession({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_profession,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchProfession')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}