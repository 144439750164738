export default [
    {
        path: '/auth/forgot/password',
        name: 'forgot-password',
        component: () => import('@/views/pages/cloudx/ForgotPassword-v1'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    } ,
    {
        path: '/auth/password/reset/:token',
        name: 'reset-password',
        component: () => import('@/views/pages/cloudx/ResetPassword-v1'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    } ,

]
