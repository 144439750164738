import axios from "@/store/axios";
import {V1API} from "@/http/APIPath";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";
import dataMagic, {decode, encode} from "@/store/dataMagic"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

const keyList = {
    concernUpdate : 'CONCERN_UPDATE',
    concernDelete : 'CONCERN_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        concern: [],
        concernLoading: false,
        concernError: false,
        submitLoading: false,
        submitError: false,
        submitSuccess: false,


        ...store
    },
    mutations : {
        ...mutations,
        SET_CONCERN (state, concern) {
            state.concern = concern;
        },

        CONCERN_LOADING (state, loading) {
            state.concernLoading = loading;
        },
        SUBMIT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        SUBMIT_ERROR (state, hasError) {
            state.submitError = hasError;
        },

        SUBMIT_SUCCESS (state, success) {
            state.submitSuccess = success;
        }
    },
    actions: {
        async updateConcern({commit, state, dispatch}, payload) {
            let response = '';
            await performNetworkCall(
                commit,
                keyList.concernUpdate,
                async () => {
                    let payloadData={
                        title: payload.title
                    }
                    let magic=encode(payloadData)
                    response = await axios.patch(V1API.concern+"/"+payload.id,magic.encodedPayload,magic.config).then(result => {
                        let data=decode(result.data)
                        return data.message
                    })

                    dispatch('fetchConcern');

                }
            )
            return response
        },



        async deleteConcern({commit, state, dispatch}, payload) {
            let response='';
            await performNetworkCall(
                commit,
                keyList.concernDelete,
                async () => {

                    response = await axios.delete(V1API.concern+'/'+payload).then(result=>{
                        let data=decode(result.data)

                        return data.message

                    });
                    dispatch('fetchConcern');
                }
            )

            return response
        },

        async fetchConcern ({ commit },payload) {
            commit('CONCERN_LOADING', true);
            commit('CONCERN_ERROR', false);
            try{

                let result = await axios.get(V1API.countries);
                let response=decode(result.data)
                console.log(response)
                commit('SET_CONCERN', response); 

            }catch(error) {

                commit('CONCERN_ERROR', true);

            }
            commit('CONCERN_LOADING', false);
        },
        async addConcern({commit,dispatch}, payload) {
            commit('SUBMIT_LOADING', true);
            commit('SUBMIT_ERROR', false);
            try{
                let magic=encode(payload)
                let response = await axios.post(V1API.create_countries,magic.encodedPayload,magic.config).then(result=>{
                    let data=decode(result.data)

                    return data.message

                });

                commit('SUBMIT_SUCCESS', true);
                dispatch ('fetchConcern')
                return response
            }catch (e) {
                commit('SUBMIT_ERROR', true);
            }finally {
                commit('SUBMIT_LOADING', false);
            }
        },

    },
    getters: {}
}
